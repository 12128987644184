.App {
  text-align: center;
  background: #212121;
  height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  background: #212121;
}